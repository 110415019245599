import React from "react"
import { hero, svg, title, logoWrapper, logo, contentWrap } from "./style"
import maiLogo from "@/images/mai-logo-2.svg"

const AnnualReportHero = props => {
  return (
    <section css={hero} {...props}>
      <div css={contentWrap}>
        <svg
          css={svg}
          viewBox="0 0 560 208"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M266.076 160.208C295.573 160.208 319.485 136.296 319.485 106.799C319.485 77.3028 295.573 53.3911 266.076 53.3911C236.58 53.3911 212.668 77.3028 212.668 106.799C212.668 136.296 236.58 160.208 266.076 160.208Z"
            fill="#FF3159"
          />
          <g>
            <path
              d="M13.5865 114.033C13.6995 114.202 13.869 114.287 14.0951 114.287H61.9019C63.0321 114.287 63.5972 114.852 63.5972 115.982V120.559C63.5972 121.69 63.0321 122.255 61.9019 122.255H3.9234C2.79321 122.255 2.22812 121.69 2.22812 120.559V115.643C2.22812 114.965 2.45416 114.23 2.90623 113.439C14.6602 96.8255 26.0185 80.3813 36.9813 64.1067C46.0228 50.7705 50.5435 39.8642 50.5435 31.3878C50.5435 25.0588 48.707 19.9447 45.0339 16.0456C41.3608 12.1464 36.5292 10.1969 30.5392 10.1969C24.5493 10.1969 19.9438 12.1464 16.3837 16.0456C12.8236 19.9447 11.1001 25.0588 11.2131 31.3878V37.1518C11.2131 38.282 10.648 38.8471 9.51781 38.8471H3.75387C2.62369 38.8471 2.05859 38.282 2.05859 37.1518V30.7097C2.17161 22.1203 4.88406 15.2262 10.1959 10.0273C15.5078 4.82849 22.3454 2.22906 30.7088 2.22906C39.0721 2.22906 46.4183 4.91325 51.7302 10.2816C57.0421 15.65 59.698 22.6854 59.698 31.3878C59.698 41.3335 54.9512 53.3699 45.4577 67.4972C39.3547 76.5387 29.9742 90.1009 17.3161 108.184L13.5865 113.439C13.4735 113.665 13.4735 113.863 13.5865 114.033Z"
              stroke="#3C008C"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M78.5154 115.389C72.8645 109.681 70.0391 102.081 70.0391 92.5873V33.2526C70.0391 23.8721 72.8645 16.3281 78.5154 10.6207C84.1664 4.91326 91.6821 2.05954 101.063 2.05954C110.443 2.05954 118.015 4.91326 123.779 10.6207C129.543 16.3281 132.425 23.8721 132.425 33.2526V92.5873C132.425 102.081 129.543 109.681 123.779 115.389C118.015 121.096 110.443 123.95 101.063 123.95C91.6821 123.95 84.1664 121.096 78.5154 115.389ZM117.337 109.625C121.406 105.5 123.44 100.047 123.44 93.2654V32.5745C123.44 25.7934 121.406 20.3403 117.337 16.2151C113.269 12.0899 107.844 10.0273 101.063 10.0273C94.2815 10.0273 89.0544 12.0899 85.0423 16.2151C81.0301 20.3403 79.024 25.7934 79.024 32.5745V93.2654C79.024 100.047 81.0301 105.5 85.0423 109.625C89.0544 113.75 94.3945 115.813 101.063 115.813C107.731 115.813 113.269 113.75 117.337 109.625Z"
              stroke="#3C008C"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M161.753 3.5853H175.146C176.276 3.5853 176.841 4.15039 176.841 5.28057V120.559C176.841 121.69 176.276 122.255 175.146 122.255H162.262C161.132 122.255 160.567 121.69 160.567 120.559V21.2162C160.567 20.9901 160.482 20.7924 160.312 20.6228C160.143 20.4533 159.945 20.425 159.719 20.5381L143.275 25.7934L142.597 25.963C141.919 25.963 141.467 25.4544 141.241 24.4372L140.562 14.7741C140.562 13.87 140.958 13.1919 141.749 12.7398L159.55 4.09388C160.228 3.75482 160.962 3.5853 161.753 3.5853Z"
              fill="#3C008C"
            />
            <path
              d="M253.469 39.8642V93.6045C253.469 102.759 250.53 110.049 244.653 115.474C238.776 120.898 231.091 123.611 221.597 123.611C212.104 123.611 205.04 120.898 199.389 115.474C193.738 110.049 190.913 102.929 190.913 94.1131V90.553C190.913 89.4228 191.478 88.8577 192.608 88.8577H205.492C206.622 88.8577 207.188 89.4228 207.188 90.553V92.9264C207.188 97.7862 208.544 101.742 211.256 104.793C213.969 107.845 217.416 109.371 221.597 109.371C226.231 109.371 229.989 107.817 232.871 104.709C235.753 101.601 237.194 97.5036 237.194 92.4178V70.8878C237.194 70.6618 237.109 70.4922 236.94 70.3792C236.77 70.2662 236.572 70.3227 236.346 70.5487C231.939 75.7476 226.062 78.347 218.715 78.347C211.821 78.347 206.057 76.5387 201.424 72.9221C196.79 69.3055 193.456 64.3327 191.421 58.0037C189.613 53.3699 188.709 47.493 188.709 40.3728C188.709 34.0438 189.331 28.9579 190.574 25.1153C192.382 17.7691 195.886 12.1182 201.085 8.16254C206.283 4.20689 212.895 2.22906 220.919 2.22906C228.265 2.22906 234.481 4.00911 239.567 7.56919C244.653 11.1293 248.213 16.2434 250.248 22.9114C252.395 27.6582 253.469 33.3091 253.469 39.8642ZM230.328 61.8181C232.758 59.8402 234.481 57.043 235.499 53.4264C236.629 49.9229 237.194 45.6282 237.194 40.5423C237.194 35.9086 236.798 32.0094 236.007 28.8449C234.99 24.8893 233.21 21.8378 230.667 19.6904C228.124 17.5431 224.931 16.4694 221.089 16.4694C217.246 16.4694 214.195 17.5713 211.595 19.7752C208.996 21.979 207.188 25.1718 206.17 29.3535C205.379 33.0831 204.984 36.8692 204.984 40.7119C204.984 45.5717 205.549 49.8664 206.679 53.596C209.052 61.0552 213.856 64.7848 221.089 64.7848C224.818 64.7848 227.898 63.7959 230.328 61.8181Z"
              fill="#3C008C"
            />
          </g>
          <g>
            <path
              d="M294.53 196.423C294.643 196.593 294.812 196.677 295.038 196.677H342.845C343.975 196.677 344.541 197.242 344.541 198.373V202.95C344.541 204.08 343.975 204.645 342.845 204.645H284.867C283.737 204.645 283.171 204.08 283.171 202.95V198.034C283.171 197.355 283.398 196.621 283.85 195.83C295.603 179.216 306.962 162.772 317.925 146.497C326.966 133.161 331.487 122.255 331.487 113.778C331.487 107.449 329.65 102.335 325.977 98.4361C322.304 94.5369 317.473 92.5874 311.483 92.5874C305.493 92.5874 300.887 94.5369 297.327 98.4361C293.767 102.335 292.043 107.449 292.156 113.778V119.542C292.156 120.672 291.591 121.238 290.461 121.238H284.697C283.567 121.238 283.002 120.672 283.002 119.542V113.1C283.115 104.511 285.827 97.6167 291.139 92.4178C296.451 87.219 303.289 84.6196 311.652 84.6196C320.016 84.6196 327.362 87.3038 332.674 92.6721C337.985 98.0405 340.641 105.076 340.641 113.778C340.641 123.724 335.895 135.76 326.401 149.888C320.298 158.929 310.917 172.491 298.259 190.574L294.53 195.83C294.417 196.056 294.417 196.254 294.53 196.423Z"
              stroke="#3C008C"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M359.459 197.779C353.808 192.072 350.982 184.471 350.982 174.978V115.643C350.982 106.263 353.808 98.7186 359.459 93.0112C365.11 87.3037 372.625 84.45 382.006 84.45C391.387 84.45 398.959 87.3037 404.723 93.0112C410.487 98.7186 413.369 106.263 413.369 115.643V174.978C413.369 184.471 410.487 192.072 404.723 197.779C398.959 203.487 391.387 206.34 382.006 206.34C372.625 206.34 365.11 203.487 359.459 197.779ZM398.281 192.015C402.349 187.89 404.384 182.437 404.384 175.656V114.965C404.384 108.184 402.349 102.731 398.281 98.6056C394.212 94.4804 388.787 92.4178 382.006 92.4178C375.225 92.4178 369.998 94.4804 365.986 98.6056C361.973 102.731 359.967 108.184 359.967 114.965V175.656C359.967 182.437 361.973 187.89 365.986 192.015C369.998 196.141 375.338 198.203 382.006 198.203C388.674 198.203 394.212 196.141 398.281 192.015Z"
              stroke="#3C008C"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M444.73 190.574H489.655C490.785 190.574 491.351 191.139 491.351 192.27V202.95C491.351 204.08 490.785 204.645 489.655 204.645H425.743C424.613 204.645 424.048 204.08 424.048 202.95V192.1C424.048 191.309 424.274 190.631 424.726 190.066C439.306 171.305 450.664 156.217 458.801 144.802C467.504 132.709 471.855 123.046 471.855 115.813C471.855 110.614 470.386 106.46 467.447 103.352C464.509 100.244 460.61 98.6904 455.75 98.6904C450.89 98.6904 447.189 100.244 444.307 103.352C441.425 106.46 440.04 110.557 440.153 115.643V121.577C440.153 122.707 439.588 123.272 438.458 123.272H425.404C424.274 123.272 423.709 122.707 423.709 121.577V114.287C423.935 105.358 427.071 98.1818 433.118 92.7569C439.164 87.332 446.821 84.6196 456.089 84.6196C462.531 84.6196 468.182 85.9475 473.042 88.6035C477.901 91.2594 481.659 94.9608 484.315 99.7075C486.971 104.454 488.299 109.823 488.299 115.813C488.299 125.871 483.778 137.286 474.737 150.057C469.538 157.403 461.062 168.536 449.308 183.454L444.391 189.727C444.052 190.292 444.165 190.574 444.73 190.574Z"
              fill="#3C008C"
            />
            <path
              d="M559.842 167.858C559.842 174.865 558.768 181.137 556.621 186.675C554.247 193.004 550.631 197.808 545.771 201.085C540.911 204.363 534.865 206.001 527.631 206.001C519.381 206.001 512.487 203.826 506.949 199.475C501.411 195.123 497.908 189.275 496.438 181.928C495.76 178.538 495.308 174.413 495.082 169.553C495.082 168.423 495.647 167.858 496.777 167.858H509.661C510.792 167.858 511.357 168.423 511.357 169.553C511.583 173.283 511.922 176.334 512.374 178.707C513.278 182.889 515.03 186.138 517.629 188.455C520.229 190.772 523.563 191.931 527.631 191.931C531.361 191.931 534.469 190.942 536.955 188.964C539.442 186.986 541.25 184.076 542.38 180.233C543.397 176.504 543.906 171.983 543.906 166.671C543.906 160.568 543.171 155.539 541.702 151.583C540.459 148.079 538.651 145.423 536.277 143.615C533.904 141.807 530.965 140.903 527.462 140.903C525.767 140.903 523.958 141.411 522.037 142.428C521.585 142.655 521.246 142.768 521.02 142.768C520.568 142.768 520.116 142.542 519.664 142.089L513.052 134.969C512.713 134.63 512.543 134.235 512.543 133.783C512.543 133.331 512.713 132.935 513.052 132.596L538.82 100.894C539.046 100.668 539.103 100.47 538.99 100.301C538.877 100.131 538.707 100.047 538.481 100.047H498.134C497.003 100.047 496.438 99.4815 496.438 98.3513V87.671C496.438 86.5409 497.003 85.9758 498.134 85.9758H557.638C558.768 85.9758 559.333 86.5409 559.333 87.671V98.8599C559.333 99.651 559.107 100.329 558.655 100.894L534.752 130.901C534.526 131.127 534.469 131.324 534.582 131.494C534.695 131.663 534.865 131.748 535.091 131.748C540.176 132.2 544.528 133.811 548.144 136.58C551.761 139.349 554.53 143.107 556.451 147.853C558.711 153.391 559.842 160.059 559.842 167.858Z"
              fill="#3C008C"
            />
          </g>
        </svg>
        <h1 css={title}>
          <span className="red-text">Asia & </span>
          <br />
          <span className="red-text">Americas</span> Riding-hailing <br />{" "}
          <span className="outline">Report</span>
        </h1>
        <div css={logoWrapper}>
          <span>By</span>
          <img css={logo} src={maiLogo} alt="Measurable AI" />
        </div>
      </div>
    </section>
  )
}

export default AnnualReportHero
